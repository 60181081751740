import React, { useState } from 'react'
import styled from 'styled-components'
import { MenuButton } from '../navigation/menu-button'
import { Navigation } from '../navigation'
import { useWindowSize } from '../../utils/use-window-size'
import { useWindowScroll } from '../../utils/use-window-scroll'
import { HeaderLogo } from '../logo'
import { Banner } from './banner'

const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: rgb(0,0,0);
  background: ${({ scrollTop, isMenuOpen }) => scrollTop > 0 || isMenuOpen ? "rgba(0, 0, 0, 0.5)" : "transparent" };
  transition: all 300ms ease;
`
// desktop view nav bar is positioned in the wrapper to show inline with logo
const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  padding: 1rem;
  z-index: 10;
  max-width: 75vw;
  margin: 0 auto;
  z-index: 10;
  @media(min-width: 1024px) {
    max-width: 1024px;
  }
`
// keep the mobile navbar on it's own row & hide on desktop
const MobileNavContainer = styled.div`
  position: relative;
  flex-basis: 100%;
  background: transparent;
`

export const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { width } = useWindowSize() // render the nav bar with javascript to improve performance
  const { scrollTop } = useWindowScroll()

  return (
    <StyledHeader scrollTop={scrollTop} isMenuOpen={isMenuOpen}>
      <Banner />
      <Wrapper>
        <HeaderLogo />
        <MenuButton isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
        {width > 1024 ? <Navigation /> : null}
      </Wrapper>
      
      {/* Mobile navbar renders using the same component outside the wrapper for more consistent positioning */}
      {width < 1024 ? (
        <MobileNavContainer>
          <Navigation isMenuOpen={isMenuOpen} />
        </MobileNavContainer>
      ) : null}
      
      
    </StyledHeader>
  )
}
