import React from 'react'
import styled from 'styled-components'
import { Link } from '../link'

const Nav = styled.nav`
  flex-basis: 100%;
  position: absolute;
  display: block;
  top: ${({isMenuOpen}) => isMenuOpen ? '0' : '-100vh'};
  left: 0;
  width: 100%;
  transition: top 300ms ease-in;
  background: transparent;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

  @media(min-width: 1024px) {
    flex-basis: auto;
    position: relative;
    top: 0;
    width: initial;
    box-shadow: none;
  }
`
const List = styled.ul`
  display: flex;
  flex-direction: column;

  @media(min-width: 1024px) {
    flex-direction: row;
    gap: 20px;
    grid-gap: 15px;
  }
`
const ListItem = styled.li`
  width: 100%;
  border-bottom: 3px solid transparent;
  background: rgba(0,0,0,0.5);

  @media(min-width: 1024px) {
    width: initial;
    border: none;
    background: transparent;
  }
`
const StyledLink = styled(Link)`
  position: relative;
  text-decoration: none;
  color: hsla(${({theme}) => theme.text}, 1);
  font-weight: 500;
  text-transform: uppercase;
  width: 100%;
  display: inline-block;
  padding: 1.5em;
  z-index: 2;
  &.active {
    color: hsla(${({theme}) => theme.accent}, 1);
  }
  // only desktop active link gets pseudo element underline
  @media(min-width: 1024px) {
    padding: 1em 1.5em;
    border-bottom: 3px solid transparent;
    &.active {
      border-bottom: 3px solid hsla(${({theme}) => theme.accent}, 1);
    }
  }
  // when hovered on devices that accept hover
  @media(hover: hover ) {
    &:hover {
      color: hsla(${({theme}) => theme.teal.hsl}, 1);
      border-bottom: 3px solid hsla(${({theme}) => theme.accent}, 1);
      transition: all 300ms ease;
    }
  }
  
`

export const Navigation = ({ isMenuOpen }) => {
  /* 
    partiallyActive prop fixes issue with activeClassName clearing on page reload
    something about trailing slashes not matching the SSR version - searching for better fix
  */

  return (
    <Nav isMenuOpen={isMenuOpen}>
      <List>
        <ListItem>
          <StyledLink partiallyActive={true} to="/products">Order Now</StyledLink>
        </ListItem>
        <ListItem>
          <StyledLink partiallyActive={true} to="/about">About</StyledLink>
        </ListItem>
        <ListItem>
          <StyledLink partiallyActive={true} to="/careers">Careers</StyledLink>
        </ListItem>
        <ListItem>
          <StyledLink partiallyActive={true} to="/contact">Contact</StyledLink>
        </ListItem>
      </List>
    </Nav>
  )
}