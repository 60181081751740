import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from '../components/link'
import { useWindowSize } from '../utils/use-window-size'

// Original file easton-craft-logo-logo-full-color-rgb-864px@72ppi.png
export const Logo = () => {
  return (

    <StaticImage
      src="../images/logo.png"
      alt="Company logo"
      objectFit="contain"
    />
  )
}
// Original file easton-craft-logo-logo-mark-reverse-rgb-864px@72ppi
export const LogoWhite = () => {
  return (
    <StaticImage 
      src="../images/logo-white.png"
      alt="Company Logo"
      objectFit="contain"
    />
  )
}

const LinkWrapper = styled(Link)`
  display: flex;
  grid-gap: 1rem;
`

export const HeaderLogo = () => {
  const { width } = useWindowSize()

  return (
    <LinkWrapper to="\" aria-label="Logo link to home page">
      <StaticImage
        src="../images/logo-white.png"
        alt="Company logo"
        objectFit="contain"
        loading="eager"
        placeholder="blurred"
        style={{
          width: '50px',
          height: 'auto',
        }}
      />
      {width > 768 ? (
        <StaticImage
          src="../images/logotype-white.png"
          alt="Company logo text"
          objectFit="contain"
          loading="eager"
          placeholder="blurred"
          style={{
            width: '200px',
            height: 'auto'
          }}
        />
      ) : null}
    </LinkWrapper>
  )
}