import styled from 'styled-components'

export const H1 = styled.h1`
  font-weight: 900;
  font-size: 1.953rem;
  @media(min-width: 480px) {
    font-size: 2.441rem;
  }
  @media(min-width: 768px) {
    font-size: 3.052rem;
  }
`
export const H2 = styled.h2`
  font-size: 1.563rem;
  font-weight: 900;
  @media(min-width: 480px) {
    font-size: 1.953rem;
  }
  @media(min-width: 768px) {
    font-size: 2.441rem;
  }
`
export const H3 = styled.h3`
  font-weight: 900;
  font-size: 1.25rem;
  @media(min-width: 480px) {
    font-size: 1.563rem;
  }
  @media(min-width: 768px) {
    font-size: 1.953rem;      
  }
`
export const H4 = styled.h4`
  font-weight: 900;
  font-size: 1.25rem;
  @media(min-width: 480px) {
    font-size: 1.563rem;
  }
  @media(min-width: 768px) {
    font-size: 1.953rem;      
  }
`
export const H5 = styled.h5`
  font-weight: 700;
  font-size: 1rem;
  @media(min-width: 480px) {
    font-size: 1.25rem;
  }
  @media(min-width: 768px) {
    font-size: 1.563rem;       
  }
`
export const H6 = styled.h6`
  font-weight: 700;
  font-size: 1rem;
  @media(min-width: 480px) {
    font-size: 1.25rem;
  }
  @media(min-width: 768px) {
    font-size: 1.563rem;       
  }
`
/* End of base styles */

export const StyledHeading = styled(H1)`
  position: relative;
  display: inline-block;
  color: hsla(${({theme}) => theme.darkGray.hsl}, 1);
  background: hsla(${({theme}) => theme.teal.hsl}, 1);
  padding-top: .15em;
  padding-right: .5em;
  padding-bottom: .15em;
  padding-left: .5em;
  box-shadow: 5px 5px 0px 0px hsla(${({theme}) => theme.gray.hsl}, 1);
`