import React from 'react'
import styled from 'styled-components'
import { Link } from '../link'
import { FaFacebook, FaInstagram, FaSnapchat } from 'react-icons/fa'

const StyledLink = styled(Link)`
  color: hsl(${({theme}) => theme.darkWhite.hsl}, 1);
  opacity: .85;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 1rem;
  >:first-child {
    height: ${({size}) => size};
    width: ${({size}) => size};
    margin-right: .25em;
    font-size: 1.5rem;
  }

  @media(hover: hover) {
    &:hover {      
      color: hsla(${({theme}) => theme.teal.hsl}, 1);
    }
  }
`
export const SocialLink = ({ accountName, url, size }) => {
  const DefaultIcon = () => <div>Icon Missing</div>
  const icons = {
    Facebook: FaFacebook,
    Instagram: FaInstagram,
    Snapchat: FaSnapchat,
    default: DefaultIcon,
  }
  const Component = icons[accountName] ? icons[accountName] : icons["default"]

  return (
    <StyledLink to={url} aria-label={`Easton Craft's ${accountName} profile`} size={size}>
      <Component />
    </StyledLink>
  )
}
