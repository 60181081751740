import React from 'react'
import styled from 'styled-components'
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa'
import { Link } from '../link'
import { SocialMedia } from '../social-media'

const Container = styled.div`
  background: ${({theme}) => theme.teal.hex};
`
const Inner = styled.div`
  display: grid;
  padding: .5em;
  grid-template-columns: 1fr 1fr;
  margin: 0 auto;
  max-width: 75vw;

  @media(min-width: 1024px) {
    grid-template-columns: 15% 70% 15%;
    max-width: none;
  }
`
const Wrapper = styled.div`
  grid-column: 1;
  display: flex;
  justify-content: start;
  grid-gap: 2.5rem;
  flex: 1;
  margin-left: .25em;
  @media(min-width: 1024px) {
    grid-column: 2/3;
    justify-content: center;
    margin-left: 0;
  }
`
const InfoLink = styled(Link)`
  display: flex;
  align-items: center;
  grid-gap: .5em;
  text-decoration: none;
  color: ${({theme}) => theme.darkGray.hex};
  /* hide email and address on mobile due to lack of space */
  &:nth-child(n+2) {
    display: none;
  }
  @media(min-width: 1024px) {
    &:nth-child(n+2) {
      display: flex;
    } 
  }

`
// TODO apply theme to social links
const SocialLinks = styled(SocialMedia)`  
  grid-column: 2;
  justify-self: end;
  &>* {
    color: ${({theme}) => theme.darkGray.hex};
    @media(hover: hover) {
      &:hover {
        color: ${({theme}) => theme.purple.hex};
      }
    }
  }
  @media(min-width: 1024px) {
    grid-column: 3/4;
  }
`
export const Banner = () => {
  return (
    <Container>
      <Inner>
        <Wrapper>          
          <InfoLink to="tel:+6165236252"><FaPhoneAlt />616.523.6252</InfoLink>
          <InfoLink to="https://goo.gl/maps/7vdkprSDuVER8Wb17"><FaMapMarkerAlt />1110 W Lincoln Ave Ionia, MI</InfoLink>
          <InfoLink to="mailto:info@eastoncraft.com"><FaEnvelope />info@eastoncraft.com</InfoLink>
        </Wrapper>
        <SocialLinks size="1.25rem" />
      </Inner>
    </Container>

  )
}

/* 
  phone           social
  address         email
*/