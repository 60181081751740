import React from 'react'
import styled from 'styled-components'
import { SocialLink } from './social-link'
import { useStaticQuery, graphql } from 'gatsby'

const FlexRow = styled.div`
  display: flex;
  grid-gap: 1rem;
  svg {
    height: ${({size}) => size || "1em"};
    width: ${({size}) => size || "1em"};
  }
`

export const SocialMedia = ({ className, size }) => {
  const { site } = useStaticQuery(graphql`
    query SocialQuery {
      site {
        siteMetadata {
          socialMediaLinks {
            accountName
            url
          }
        }
      }
    }
  `)
  const { socialMediaLinks } = site.siteMetadata

  return (
    <FlexRow className={className}>
      {socialMediaLinks.map((link) => (
        <SocialLink size={size} key={link.url} {...link} />
      ))}
    </FlexRow>
  )
}
