/* 
  This hook will return the current screen size and can be used
  for conditionally rendering the same component with different props
  instead of hiding and showing them with media queries. -- performance enhancement
*/
import { useState, useEffect } from 'react'

export const useWindowSize = () => {
  const isSSR = typeof window === "undefined"
  const [windowSize, setWindowSize] = useState({
    width: isSSR ? 1200 : window.innerWidth,
    height: isSSR ? 800 : window.innerHeight,
  })

  const changeWindowSize = () => {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight})
  }

  useEffect(() => {
    window.addEventListener("resize", changeWindowSize)

    // clean up event listener
    return () => {
      window.removeEventListener("resize", changeWindowSize)
    }
  }, [])

  return windowSize;
}