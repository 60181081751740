import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
  display: flex;
  align-items: center;
  margin: 0;
  padding: .5rem;
  background: transparent;
  border: none;
  outline: none;
  color: #fff;
  font-size: 1.2rem;
  text-transform: uppercase;
  z-index: 10;
  
  @media(min-width: 1024px) {
    display: none;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    transition: all 300ms ease-out;
    height: 1.5rem;
    width: 1.5rem;
    
    div {
      background: #fff;    
      width: 1.5rem;
      height: 2px;
      transform-origin: 1px;
      
      :first-child {
        transform: ${({ isMenuOpen }) => isMenuOpen ? 'rotate(45deg)' : 'rotate(0)'};
      }
      :nth-child(2) {
        opacity: ${({ isMenuOpen }) => isMenuOpen ? '0' : '1'};
        transform: ${({ isMenuOpen }) => isMenuOpen ? 'translateX(20px)' : 'translateX(0)'};
      }
      :last-child {
        transform: ${({ isMenuOpen }) => isMenuOpen ? 'rotate(-45deg)' : 'rotate(0)'};
      }
    }
  }
`

export const MenuButton = ({ isMenuOpen, setIsMenuOpen }) => {

  const handleClick = e => {
    e.preventDefault()
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <Button
      onClick={e => handleClick(e)}
      isMenuOpen={isMenuOpen}
    >
      <div>
        <div />
        <div />
        <div />
      </div>
    </Button>
  )
}