import { useState, useEffect } from 'react'

export const useWindowScroll = () => {
  const [isScrolling, setIsScrolling] = useState(false)
  const [scrollTop, setScrollTop] = useState(0)

  useEffect(() => {
    const onScroll = (event) => {
      setScrollTop(event.target.documentElement.scrollTop)
      setIsScrolling(event.target.documentElement.scrollTop > scrollTop)
    }
    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener("scroll", onScroll)
  }, [scrollTop])

  return { isScrolling, scrollTop }
}