import React from 'react'
import styled from 'styled-components'
import { Link } from './link'
import { FaMapMarkerAlt, FaPhoneAlt, FaRegEnvelope } from 'react-icons/fa'
import { Small } from '../components/page-elements'
import { SocialMedia } from './social-media'

const StyledFooter = styled.footer`
  padding: 2rem;
  background: hsla(${({theme}) => theme.darkPurple.hsl}, 1);
  >* {
    color: #fff;
  }

  @media(min-width: 640px) {
    padding-top: 4rem;
  }
`
const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  gap: 2.5rem;
  grid-gap: 2.5rem;

  /* copyright element */
  >:last-child {
    flex-basis: 100%;
    text-align: center;
  }
  @media(min-width: 640px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
`
const Heading = styled.p`
  color: #fff;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 1em;
`
const GeneralInfoContainer = styled.div`
  /* target items after first excluding last */
  >:nth-child(n+2):not(:last-child) {
    margin-bottom: 1em;
    display: block;
  }  
  svg {
    margin-right: .5em;
  }
  address > a {
    display: flex;
  }
  a {
    text-decoration: none;
    color: hsla(${({theme}) => theme.darkWhite.hsl}, 1);
    @media(hover: hover) {
      &:hover {
        color: hsla(${({theme}) => theme.teal.hsl}, 1);
      }
    }
  }
`
const HoursContainer = styled.div`
  ul {
    display: grid;
    grid-template-columns: 100px auto;
    gap: .5em;
    grid-gap: .5em;
  }
`
const NavList = styled.ul`
  li {
    margin-bottom: .5em;
  }
`
const StyledLink = styled(Link)`
   text-decoration: none;
   color: hsla(${({theme}) => theme.darkWhite.hsl}, 1);

   @media(hover: hover) {
     &:hover {
       color: hsla(${({theme}) => theme.teal.hsl}, 1);
       font-weight: 700;
     }
   }
`
export const Footer = () => {

  return (
    <StyledFooter>
      <InnerContainer>

        <GeneralInfoContainer>
          <Heading>Easton Craft</Heading>            
          <Small><Link to="tel:+6165236252"><FaPhoneAlt />(616) 523-6252</Link></Small>
          <Small><Link to="mailto:info@eastoncraft.com"><FaRegEnvelope />info@eastoncraft.com</Link></Small>
          <Small as="address">
            <Link to="https://goo.gl/maps/7vdkprSDuVER8Wb17">
              <FaMapMarkerAlt />
              <div>
                1110 W Lincoln Ave<br/>
                Ionia, MI 48846
              </div>
            </Link>
          </Small>
        </GeneralInfoContainer>

        <HoursContainer>
          <Heading>Business Hours</Heading>
          <ul>
            <li><Small>Monday - Friday</Small></li>
            <li><Small>10AM - 10PM</Small></li>
            <li><Small>Saturday</Small></li>
            <li><Small>10AM - 10PM</Small></li>
            <li><Small>Sunday</Small></li>
            <li><Small>10AM - 6PM</Small></li>
          </ul>
        </HoursContainer>

        <div>
          <Heading>Navigation</Heading>
          <NavList>
            <li><Small><StyledLink to="/">Home</StyledLink></Small></li>
            <li><Small><StyledLink to="/about">About</StyledLink></Small></li>
            <li><Small><StyledLink to="/careers">Careers</StyledLink></Small></li>
            <li><Small><StyledLink to="/contact">Contact Us</StyledLink></Small></li>
          </NavList>
        </div>

        <div>
          <Heading>Follow Us</Heading>
          <SocialMedia />
        </div>

        <div>
          <Small>&copy; 2021 Easton Craft All Rights Reserved</Small>
        </div>
      </InnerContainer>
    </StyledFooter>
  )
}
