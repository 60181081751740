
import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

export const Link = ({ children, to,  className, ...rest }) => {
  // regex test - internal link starts with slash(/) but not in-page link(#)
  const internal = /^\/(?!\/)/.test(to)

  if (internal) {
    return (
      <GatsbyLink
        to={to}
        className={className}
        activeClassName="active"
        {...rest}
      >
        {children}
      </GatsbyLink>
    )
  }

  return (
    <a href={to} className={className} {...rest}>
      {children}
    </a>
  )
}