import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"

export const Seo = ({ description, meta, image, title, pathname, author }) => {
  const { site, file } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keywords
            siteUrl
          }
        }
        file(relativePath: {eq: "logo-color-tagline.jpg"}) {
          publicURL
        }
      }
    `
  )

  const seoConfig = {
    title: title ? title : site.title,
    metaDescription: description || site.siteMetadata.description,
    image: image 
      ? `${site.siteMetadata.siteUrl}${image}` 
      : `${site.siteMetadata.siteUrl}${file.publicUrl}`,
    canonical: pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null,
    meta: meta ? meta : [],
    lang: 'en',
    keywords: site.siteMetadata.keywords
      ? site.siteMetadata.keywords.join(',')
      : "",
    author: author ? author : site.siteMetadata.author,

  }

  return (
    <Helmet
      htmlAttributes={{
        lang: seoConfig.lang
      }}
      title={seoConfig.title}
      titleTemplate={`%s | ${seoConfig.title}`}
      link={
        seoConfig.canonical
          ? [
              {
                rel: "canonical",
                href: seoConfig.canonical,
              },
            ]
          : []
      }
      meta={[
        {
          name: 'description',
          content: seoConfig.metaDescription,
        },
        {
          name: 'keywords',
          content: seoConfig.keywords,
        },
        {
          name: 'og:title',
          content: seoConfig.title,
        },
        {
          property: `og:description`,
          content: seoConfig.metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:creator`,
          content: seoConfig.author,
        },
        {
          name: `twitter:title`,
          content: seoConfig.title,
        },
        {
          name: `twitter:description`,
          content: seoConfig.metaDescription,
        },
      ]
        .concat(
          image
            ? [
                {
                  property: "og:image",
                  content: seoConfig.image,
                },
                {
                  property: "og:image:width",
                  content: seoConfig.image.width,
                },
                {
                  property: "og:image:height",
                  content: seoConfig.image.height,
                },
                {
                  name: "twitter:card",
                  content: "summary_large_image",
                },
              ]
            : [
                {
                  name: "twitter:card",
                  content: "summary",
                },
              ]
        )
        .concat(seoConfig.meta)}
    />
  )
}
