import React, { useState } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  position: relative;
  width: 100%;
`
const StyledLabel = styled.label`
 color: ${({theme, hasFocus}) => hasFocus ? theme.teal.hex : theme.darkWhite.hex};

`
const StyledTextArea = styled.textarea`
  position: relative;
  display: block;
  width: 100%;
  padding: .25em;
  outline: none;
  background: transparent;
  border: none;
  border-bottom: 1px solid hsla(${({theme}) => theme.darkWhite.hsl}, .5);
  border-left: 1px solid hsla(${({theme}) => theme.darkWhite.hsl}, .5);
  color: hsla(${({theme}) => theme.darkWhite.hsl}, 1);
  font-family: 'Roboto', sans-serif;
  font-weight: 500;

  &:focus {
    border-bottom: 1px solid hsla(${({theme}) => theme.teal.hsl}, 1);
    border-left: 1px solid hsla(${({theme}) => theme.teal.hsl}, 1);
  }
`
const Error = styled.small`
  color: hsla(${({theme}) => theme.errorRed.hsl}, 1);
`
export const TextArea = ({ label, value, onChange, onBlur, error, ...rest }) => {
  const [hasFocus, setHasFocus] = useState(false)
  const onFocus = () => setHasFocus(true)

  const handleBlur = (event) => {
    if (onBlur) {
      onBlur(event)
    }
    setHasFocus(false)
  }

  return (
    <Container>
      <StyledLabel
        value={value}
        hasFocus={hasFocus}
      >
        {label}
      </StyledLabel>
      <StyledTextArea
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={e => handleBlur(e)}
        {...rest}
      />
      {error && <Error>{error}</Error>}
    </Container>
  )
}