import React from 'react'
import styled from 'styled-components'

const StyledInput = styled.input`
  border: none;
  outline: none;
  padding: 1em 1.5em;
  cursor: pointer;
  background: hsla(${({theme}) => theme.teal.hsl}, 1);
  color: hsla(${({theme}) => theme.darkGray.hsl}, 1);
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  font-weight: 700;

  @media(hover: hover) {
    &:hover {
      background: hsla(${({theme}) => theme.darkWhite.hsl}, 1);      
    }
  }

  &:disabled {
    opacity: .25;
    cursor: default;
  }
`

export const InputButton = ({ type, value, onClick, disabled, ...rest }) => {
  return (
    <StyledInput
      type={type}
      value={value}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    />
  )
}