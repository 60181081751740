import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle } from '../theme/global-style'
import { colors, light, dark } from '../theme/theme'
import { AgeGate } from '../components/age-gate'
import { Seo } from '../components/seo'
import { Header } from '../components/header'
import { Footer } from '../components/footer'

const FlexWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: hsla(${({theme}) => theme.background}, 1);

  main {
    flex: 1 0 auto;
  }
  footer {
    flex-shrink: 0;
  }
`
// For use while testing theme styles
// const ThemeToggle = styled.button`
//   position: absolute;
//   top: 0;
//   left: 0;
//   z-index: 100;
// `

const Layout = ({ children, title, pathname, ...rest }) => {
  // temp spreading color into theme until theme modes are implemented
  const [theme, setTheme] = useState({ ...colors, ...dark })
  const [isAdult, setIsAdult] = useState(false)
  const [loading, setLoading] = useState(true)

  // will be used later to control theme state
  /* eslint-disable no-unused-vars */
  const handleTheme = e => {
    e.preventDefault()
    if (theme.mode === 'light') {
      setTheme({ ...theme, ...dark })
    } else {
      setTheme({ ...theme, ...light })
    }
  }
  /* eslint-disable no-unused-vars */

  useEffect(() => {
    if (localStorage.getItem('age-gate')) {
      setIsAdult(true)
    }
    setLoading(false)
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Seo title={title} {...rest} />
      <FlexWrapper>
      {!isAdult && !loading
        ? <AgeGate isAdult={isAdult} setIsAdult={setIsAdult} /> 
        : null}
        {/* <ThemeToggle onClick={e => handleTheme(e)}>Toggle Theme</ThemeToggle> */}
        <Header />
        <main>
          {children}
        </main>
        <Footer />
      </FlexWrapper>
    </ThemeProvider>
  )
}

export default Layout
