import React, { useState } from 'react'
import styled from 'styled-components'
import { LogoWhite } from './logo'
import { H1 } from '../components/page-elements'
import { InputButton } from '../components/inputs'

const Cover = styled.div`
  background: rgba(0,0,0,0.25);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Modal = styled.div`
  position: relative;
  text-align: center;
  background: hsl(${({theme}) => theme.darkPurple.hsl}, .95);
  color: hsl(${({theme}) => theme.darkWhite.hsl}, 1);
  padding: 2rem;
  min-height: 60vh;
  min-width: 60vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  div {    
    display: flex;
    grid-gap: 2rem;
  }
  >*{
    margin-bottom: 2.5rem;
  }
`
const ErrorMessage = styled.small`
  color: red;
  margin: 0;
  font-weight: 700;
  font-size: 1.5rem;
`
const LogoContainer = styled.div`
  width: 200px;
  margin-left: auto;
  margin-right: auto;
`
export const AgeGate = ({ isAdult, setIsAdult }) => {
  const [hasClicked, setHasClicked] = useState(false)

  const handleClick = event => {
    const { value } = event.target
    setHasClicked(true)    
    if (value === 'Yes') {
      localStorage.setItem('age-gate', JSON.stringify(true))
      setIsAdult(true)
    } else {
      setIsAdult(false)
    }
  }

  return (
    <Cover>
      <Modal>
        <LogoContainer>
          <LogoWhite />
        </LogoContainer>
        <H1>Are you over 21 years of age?</H1>
        <div>
          <InputButton type="button" value="Yes" onClick={e => handleClick(e)} />
          <InputButton type="button" value="No" onClick={e => handleClick(e)} />          
        </div>
        {!isAdult && hasClicked ?
          <ErrorMessage>Please come back when you're old enough.</ErrorMessage>
          : null}
      </Modal>
    </Cover>
  )
}
