export const colors = {
  darkWhite: {
    hsl: '202deg, 23%, 93%',
    rgb: '234, 239, 242',
    hex: '#eaeff2',
  },
  teal: {
    hsl: '168deg, 95%, 75%',
    rgb: '128, 252, 227',
    hex: '#80fce3',
  },
  purple: {
    hsl: '291deg, 35%, 50%',
    rgb: '121, 83, 128',
    hex: '#795380',
  },
  darkPurple: {
    hsl: '291deg, 35%, 10%',
    rgb: '48, 25, 52',
    hex: "#301934",
  },
  gray: {
    hsl: '0deg, 0%, 66%',
    rgb: '66%, 66%, 66%',
    hex: '#A9A9A9',
  },
  darkGray: {
    hsl: '0deg, 0%, 25%',
    rgb: '64, 64, 64',
    hex: '#404040',
  },
  errorRed: {
    hsl: '0deg, 67%, 65%',
    rgb: '226%, 106%, 106%',
    hex: '#e26a6a',
  }
}

// reference colors from above when implementing dark and light mode -- later
export const light = {
  mode: 'light',
  background: colors.darkWhite.hsl,
  text: colors.darkPurple.hsl,
  accent: colors.teal.hsl,
}

export const dark = {
  mode: 'dark',
  background: colors.darkPurple.hsl,
  text: colors.darkWhite.hsl,
  accent: colors.teal.hsl,
}


/* ----- Styles Saved For Later ----- */
/* Dark to transparent vertical gradient */
/* background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 20%, rgba(0,0,0,0.4) 50%, rgba(0,0,0,0.6) 80%, rgba(0,0,0,0.6) 100%);  */

/* Box shadow used for the bottom edge of the header */
/* box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
  rgba(0, 0, 0, 0.06) 0px 1px 2px 0px; */