import styled from 'styled-components'

export const P = styled.p`    
  font-weight: 300;
  max-width: 70ch;
  font-size: 1rem;
  @media(min-width: 1440px) {
    font-size: 1.25rem;
  }
`
export const LargeText = styled(P)`
  font-size: 1.563rem;
  @media(min-width: 1440px) {
    font-size: 1.953rem;
  }
`
export const MediumText = styled(P)`
  font-size: 1.25rem;
  @media(min-width: 1440px) {
    font-size: 1.563rem;
  }
`

export const Small = styled.small`
  font-weight: 300;
  max-width: 70ch;
  font-size: 0.8rem;
`
export const ExtraSmall = styled(Small)`
  font-size: 0.64rem;
`
export const TooSmall = styled(Small)`
  font-size: 0.512rem;
`